
const getDefaultState = () => {
  return {
    assetArray: [],
    stationArray: [],
    deviceTypeArray: [],
    statusArray: [],
    selectedAssetId: null,
    curDeviceInfo: {},
    curResInfo: {},
    levelArray: [
      { id: 1, name: 'A' },
      { id: 2, name: 'A+' },
      { id: 3, name: 'A++' },
      { id: 4, name: 'S' },
      { id: 5, name: 'S+' },
      { id: 6, name: 'S++' }
    ],
    productAssetArray: []
  }
}

const state = getDefaultState()

const deviceManage = {
  state,
  mutations: {
    reset_state (state) {
      Object.assign(state, getDefaultState())
    },
    set_asset_array (state, data) {
      state.assetArray = data
    },
    set_station_array (state, data) {
      state.stationArray = data
    },
    set_devicetype_array (state, data) {
      state.deviceTypeArray = data
    },
    set_status_array (state, data) {
      state.statusArray = data
    },
    set_selected_assetId (state, data) {
      state.selectedAssetId = data
    },
    set_cur_device_info (state, data) {
      state.curDeviceInfo = data
    },
    set_cur_res_info (state, data) {
      state.curResInfo = data
    },
    set_product_asset_array (state, data) {
      state.productAssetArray = data
    }
  }
}

export default deviceManage
